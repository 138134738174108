import './Article.css';


function Article2() {

    return (<div className='article'>
        <h1>Automatic differentiation in C++ from scratch</h1>

        <p>
            Computational graphs are a powerful abstraction used in computer science, especially in machine learning and deep learning.
            They provide a visual and mathematical way to represent complex computations by breaking them down into a series of simple operations connected in a network-like structure.
            Moreover, this structure plays an important role in the computation of derivatives, particularly with the automatic differentiation algorithm.
            Automatic differentiation is widely used by deep-learning libraries such as Pytorch and Tensorflow.
            This article explores the fundamentals of automatic differentiation and its applications by implementing a simple header only library and training a neural network to recognize hand-written digits from the MNIST dataset.
            All of this in C++20 with the help of the <a href='https://eigen.tuxfamily.org/index.php' target="_blank" rel="noopener noreferrer">Eigen library</a> for optimized common linear algebra operation.
        </p>

        <h2>How computational graphs work</h2>
        <p>A computational can be represented using nodes and edges, where each node represents a variable and an operation, and the edges represent the flow of data between them.</p>
        <img src='/a2-1.png' alt='computational graph' />
        <p>Each node only depends on its inputs.</p>
        <p>The graph is evaluated from bottom to top. Computational graphs are widely used because they make easier the computation of derivatives by using automatic differentiation.</p>

        <h2>Calculating the derivatives</h2>
        <p>To calculate the derivative of a function we could use numerical differentiation, which relies on the definition of the partial derivative</p>
        {String.raw`
        $$\dfrac{\partial}{\partial x_i} f(\textbf{a}) = \lim_{h \to 0} \frac{f(\textbf{a} + h\textbf{e}_i) - f(\textbf{a})}{h}$$
        `}
        <p>
            {String.raw`
        Where \(\textbf{e}_i\) is the unit vector whose \(i\)-th component is \(1\) and all the other component are \(0\)
        `}
        </p>


        <p>Numerical differentiation calculates an approximation of the derivative by letting \(h\) be a small finite number.
            This method is however not optimal as it is imprecise, is affected by floating point rounding errors, and requires the graph to be evaluated for every node whose derivative is required.</p>
        <p>A better method would be to use automatic differentiation which doesn't have these two flaws.</p>

        <h3>Automatic differentiation</h3>
        <p>
            There are two ways to compute the derivative using automatic differentiation: forward-mode and reverse-mode differentiation.
            The first traverses the graph from the inputs to the outputs and calculates the derivative of that node with respect to an input, the latter traverses the graph in the opposite direction and calculates the derivative of an output with respect to that node.
            Note that reverse-mode differentiation is really just an application of the chain rule.
        </p>

        <img src='/a2-2.png' alt='backward-mode illustration' />
        <p className='legend'>Forward-mode differentiation when calculating {String.raw`\(\frac{dx_6}{dx_2}\)`}</p>
        <img src='/a2-3.png' alt='backward-mode illustration' />
        <p className='legend'>Reverse-mode differentiation when calculating {String.raw`\(\frac{dx_6}{dx_2}\)`}</p>
        <p>
            These two algorithm can achieve the same result. However, their crucial difference is the number of times the graph must be traversed.
            To compute all the inputs derivative with forward-mode differentiation, the graph would need to be traversed as many times as there are inputs.
            On the other hand, reverse-mode differentiation only requires the graph to be traversed once in order to compute all the inputs derivative.
            In this article, we are going to use reverse-mode differentiation as it is more suited for graphs with fewer outputs than inputs, which is close to always in the case of deep learning.
        </p>
        <h3>Small note</h3>
        <p>The derivative of an input in a graph is the sum of all the paths from that input to the output. This follows from the multivariate chain rule:</p>
        {String.raw`
        $$\frac{dh}{dx_1} = \frac{\partial h}{\partial x_2}\frac{df}{dx_1} + \frac{\partial h}{\partial x_3}\frac{dg}{dx_1}$$
        `}
        <img src='/a2-4.png' alt='graph' />
        <h2>Using matrices</h2>
        <p>Calculating matrices derivatives is not as straightforward as with scalars, in this section we will break down the math and demonstrate some formulas.</p>
        <h3>Calculating the derivative of the matrix product</h3>
        <p>Let \(\textbf C\) be the matrix product of {String.raw`\(\textbf{A} \in \mathbb{R}^{m \times n}\)`} and {String.raw`\(\textbf{B} \in \mathbb{R}^{n \times p}\)`}</p>
        {String.raw`$$\textbf{C}=\textbf{A}\textbf{B}\nonumber$$`}
        <p>The equation above can also be written with the sigma notation as</p>
        {String.raw`$$\textbf{C}_{i,j}=\sum_{k=1}^n \textbf{A}_{i,k} \textbf{B}_{k,j}$$`}
        <p>Therefore, the partial derivative of {String.raw`\(\textbf{C}_{i,j}\)`} with respect to {String.raw`\(\textbf{A}_{i,k}\)`}  and {String.raw`\(\textbf{B}_{k,j}\)`} are respectively</p>
        {String.raw`$$\frac{\partial \textbf{C}_{i,j}}{\partial \textbf{A}_{i,k}}=\textbf{B}_{k,j}$$`}
        {String.raw`$$\frac{\partial \textbf{C}_{i,j}}{\partial \textbf{B}_{k,j}}=\textbf{A}_{i,k}$$`}
        {String.raw`$$\text{ For all possible } i,j,k$$`}

        <p>Now let's assume the final output \(f(\textbf C)\) is a scalar, then {String.raw`\(\frac{\partial f}{\partial \textbf{C}} \in \mathbb{R}^{m \times n}\) for a function \(f \colon \mathbb{R}^{m \times n} \to \mathbb{R}\)`}.</p>
        <p>The partial derivative of \(f\) with respect to {String.raw`\(\textbf{A}_{i,k}\)`} and {String.raw`\(\textbf{B}_{k,j}\)`} are then respectively</p>
        {String.raw`$$\frac{\partial f}{\partial \textbf{A}_{i,k}} = \sum_{j=1}^{p} \left( \frac{\partial f}{\partial \textbf{C}} \right)_{i,j} \cdot \textbf{B}_{k,j}$$`}
        {String.raw`$$\frac{\partial f}{\partial \textbf{B}_{k,j}} = \sum_{i=1}^{m} \left( \frac{\partial f}{\partial \textbf{C}} \right)_{i,j} \cdot \textbf{A}_{i,k}$$`}
        <p>Written in matrix form</p>
        {String.raw`$$\frac{\partial f}{\partial \textbf{A}} = \frac{\partial f}{\partial \textbf{C}} \textbf{B}^T$$`}
        {String.raw`$$\frac{\partial f}{\partial \textbf{B}} = \textbf{A}^T \frac{\partial f}{\partial \textbf{C}}$$`}

        <h2>The implementation</h2>
        <p>We could implement some linear algebra operation ourselves, however doing this would be too long for this article.
            For this reason we are going to make use of the highly optimized Eigen library.
            Deep learning library are often using the GPU for computation instead of the CPU as this provides better performance.
            Eigen is however a CPU only library, to keep this article succint the implementation for the GPU will be ommited.
        </p>
        <h3>The Eigen library</h3>
        <p>Here is a small example of the use of Eigen, for more on the Eigen Library, see the <a href='https://eigen.tuxfamily.org/dox/group__QuickRefPage.html' target="_blank" rel="noopener noreferrer">documentation</a></p>
        <pre file-name='main.cpp' className='c++'>
            <code>
                {String.raw
                    `#include <iostream>
#include <format>

#include <Eigen/Dense>

int main() 
{
Eigen::MatrixX<float> a{{1, 2}, {3, 4}};
std::cout << std::format("Matrix a has {} rows and {} columns\n", a.rows(), a.cols());
std::cout << "Matrix a is:\n" << a << "\n";

Eigen::MatrixX<float> b{{5, 6}, {7, 8}};
std::cout << "Matrix product of a and b is:\n" << (a * b) << "\n";

std::cout << "The product of 3.14 and a is:\n" << (3.14 * a) << "\n";

std::cout << "The elementwise addition of a and b is:\n" << (a.array() + b.array()) << "\n";
}

`               }
            </code>
        </pre>
        <pre file-name="stdout" className='text'>
            <code>
                {String.raw
                    `Matrix a has 2 rows and 2 columns
Matrix a is:
1 2
3 4
Matrix product of a and b is:
19 22
43 50
The product of 3.14 and a is:
3.14  6.28
9.42 12.56
The elementwise addition of a and b is:
6  8
10 12
`}
            </code>
        </pre>

        <h3>A first approach</h3>
        <p>The <var>Matrix</var> class will represent a dense matrix for the user, it will store a shared pointer to a <var>Storage</var> class that will hold the actual data.
            The <var>Storage</var> class will be a virtual base class for all the <var>Operation[name]</var> classes.
            Theses classes will represent the result of an operation, they will keep track of their inputs and override the inherited virtual <var>backward</var> function that computes and propagates the gradient.
            Using this kind of pattern will make the computational graph able to still use a matrix storage after its <var>Matrix</var> class has already fallen out of scope.
        </p>

        <pre file-name="utils.hpp" className='c++'>
            <code>
                {String.raw
                    ``}
            </code>
        </pre>
        <p>We first define a <var>PairOf</var> type and a <var>make_polymorphic_shared</var> function that will be useful to avoid repetition later on.</p>


        <pre file-name="storage.hpp" className='c++'>
            <code>
                {String.raw
                    ``}
            </code>
        </pre>
        <p>Note that the <var>backward</var> method adds the gradient comming from different paths as evocated in the note previously</p>



        <pre file-name="operations.hpp" className='c++'>
            <code>
                {String.raw
                    ``}
            </code>
        </pre>
        <p>We define the add and substract operations they both override the <var>backward</var> and <var>clear_gradient</var> methods.</p>

        <pre file-name="matrix.hpp" className='c++'>
            <code>
                {String.raw
                    `

`}
            </code>
        </pre>~
        <p>The <var>Matrix</var> class is  just a wrapper that handles a shared pointer to a <var>Stroage</var> class</p>

        <p>Let's do some tests with the <a href='https://github.com/google/googletest' target="_blank" rel="noopener noreferrer">Google test library</a> to check that our class works correctly</p>
        <pre file-name="test_matrix.cpp" className='c++'>
            <code>
                {String.raw
                    ``}
            </code>
        </pre>

    </div>
    );
}

export default Article2;
