import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import Navbar from './components/Navbar';

import Home from './Home';
import About from './About';
import NoMatch from './NoMatch';

import Article1 from './articles/Article1';
import Article2 from './articles/Article2';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar/>
      <div className='content'>
      <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/r/*" element={<NoMatch details='invalid redirect link'/>} />
            <Route path="*" element={<NoMatch />} />

            <Route path="/a/2024-10-05" element={<Article1 />} />
            <Route path="/a/2024-10-06" element={<Article2 />} />
      </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

