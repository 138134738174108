import './About.css';

function About() {
    return (
        <div className='about'>
            <div className='author'>
                <img src="author.jpg" alt="author" />
                <h3>Bartholomé Hubert</h3>
                <p>Welcome to my personal blog, where I share my journey of self-taught exploration across a wide range of subjects. From coding and math to history and personal growth, I delve into the topics that captivate me. Join me as I document my learning experiences and share insights that might inspire your own path.</p>
            </div>

            <div className='contact'>
            <div className='links'>
                <a href='https://github.com/bartholomehubert' target="_blank" rel="noopener noreferrer">
                    <img src="icon-github.svg" alt="github icon" />
                    <p>github.com</p>
                </a>
                <a href='https://substack.com/@bartholomehubert' target="_blank" rel="noopener noreferrer">
                    <img src='icon-substack.png' alt='substack icon' />
                    <p>substack.com</p>
                </a>
            </div>
            <div className='email'>
                <h3>Email</h3>
                <p>bartholomehubert@outlook.com</p>
            </div>
            </div>
        </div>
    );
}

export default About;
