import './Home.css';
import articles from './articles'

function Home() {
    return (
        <div className='homepage'>
            <h1>Articles</h1>
            <div className="articles-thumbnail">
                {
                    articles.map(article => (
                        <div className='article-thumbnail' key={article.title}>
                            <a href={article.url}>
                                <h2>{article.title}</h2>
                                {'image' in article ? <img src={article.image.src} alt={article.image.alt}/> : null}
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Home;
