

const articles = [
    {
        url: '/a/2024-10-05',
        title: 'A quick introduction to the Eigen library',
    },
    {
        url: '/a/2024-10-06',
        title: 'Implementing a computational graphs in C++ and using it to train a deep learning model ',
        image: { src: '/a2-4.png', alt: 'computation graph illustration' },
        pinned: true
    },

    {
        url: '/a/todo1',
        title: 'Using the Dekker technique to quadruple the float precision in C++',
    },

    {
        url: '/a/todo2',
        title: 'Can LSTMs predict crypto price returns',
    },

    {
        url: '/a/todo3',
        title: 'Debian with i3 full installation guide',
    }
]


export default articles