import { NavLink } from "react-router-dom"

import './Navbar.css';

import { useEffect } from 'react';
import "highlight.js/styles/felipec.css";
import hljs from "highlight.js";

function Navbar() {

    useEffect(() => {
        hljs.highlightAll();
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typeset()
        }
    }, [])

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <NavLink to="/">B. Hubert's blog</NavLink>
            </div>
            <div className="navbar-right">
                <NavLink to="/">Blog</NavLink>
                <NavLink to="/about">About</NavLink>
            </div>
        </nav>
    );
}

export default Navbar;
