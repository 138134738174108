import './Article.css'

function Article1() {
    return (<div className='article'>
        <h1>A quick introduction to the Eigen library</h1>
        <p>Eigen is a C++ template library for linear algebra. 
            It is widely used for its performance and its convenience. 
            In this introduction I am going to give an overview of some of the Eigen features that are used in the <a href='/a/2024-10-06'>blog article</a> I have written about automatic differentiation. 
            I am writting this article for the readers that are new to the library and don't want to delve in the documentation. 
            It is thus aimed to be a short summary, for a detailed tutorial on Eigen I suggest reading <a href='https://eigen.tuxfamily.org/dox/group__DenseMatrixManipulation__chapter.html' target="_blank" rel="noopener noreferrer">the documentation on dense matrices.</a> </p>
        <p></p>
        <p>Let's start with the <var>Matrix</var> class, which represents a dense column-major matrix.</p>
        <pre><code>{`Matrix<typename Scalar, int RowsAtCompileTime, int ColsAtCompileTime>`}</code></pre>
        <p>The first of its template argument is the data type and the remaining two ones represent the matrix dimensions at compile time. A dynamically sized matrix is represented with a <var>Matrix</var> whose dimensions are all <var>-1</var>.</p>
        <p>The <var>{`MatrixX<typename Scalar>`}</var> type is an alias of <var>{`Matrix<typename Scalar, -1, -1>`}</var> that will be handy.
        In addition there exist the <var>MatrixXf</var>, <var>MatrixXd</var> and <var>MatrixXi</var> types that represent matrices of data type of float, double and int respectively.</p>
        <pre><code className='c++'>{String.raw
`#include <iostream>
#include <Eigen/Dense>

int main()
{
    Eigen::Matrix<float, 2, 2> a{{1, 2}, {3, 4}}; // fixed size matrix

    std::cout << "a is \n" << a << "\n";

    // a.resize(4, 5) compile time error

    Eigen::Matrix<float, -1, -1> b(4, 3); // dynamic 2x3 matrix

    b.resize(2, 2);

    b.setZero(); // sets all values to 0

    b(0, 0) = 2;
    b(1, 1) = 2;

    std::cout << "b is \n" << b << "\n";

    // MatrixX<typename Scalar> is an a alias for Matrix<typename Scalar, -1, -1>
    Eigen::MatrixX<float> c = a * b;
    std::cout << "Eigen works: " << std::boolalpha << (c == Eigen::MatrixX<float>{{2, 4}, {6, 8}}) << "\n";

    Eigen::MatrixX<float> d = 3.14 * a * b + Eigen::MatrixX<float>::Constant(2, 2, 1.18);

    std::cout << "The result is \n" << d << "\n";

    // All the operation made with eigen are lazy evaluated
    // This allows expressions to be evaluated in a single loop instead of creating temporaries
    // The type of e isn't actually a Matrix but a templatized and complicated expression type
    auto e = 2 * (a + b) + 3.14 * (a - b);
    // The value of any eigen expression is only evaluated when assigned to a Matrix
    Eigen::MatrixX<float> f = e;

    std::cout << "f is \n" << f << "\n";

    // the Matrix class has reduction method such as prod, sum, mean, norm
    std::cout << "f.sum() is " << f.sum() << "\n";
    std::cout << "f.prod() is " << f.prod() << "\n";
    std::cout << "The mean of f is " << f.mean() << "\n";
    std::cout << "The norm of f is " << f.norm() << "\n";

    // Eigen::Matrix::array() method can be called to do elementwise operations 
    Eigen::MatrixX<float> g = a.array() * b.array();
    Eigen::MatrixX<float> h = g.array() + 5;

    // the Matrix class also implements elementwise functions, they start with cwise (for coefficient-wise)
    Eigen::MatrixX<float> i = h.cwiseAbs() + h.cwiseInverse();

}`}
        </code></pre>

        <pre file-name="stdout" className='text'>
            <code>{String.raw
`a is 
1 2
3 4
b is 
2 0
0 2
Eigen works: true
The result is 
 7.46 13.74
20.02  26.3
f is 
0 4
6 6
f.sum() is 16
f.prod() is 0
The mean of f is 4
The norm of f is 9.38083`}
            </code>
        </pre>
        <p>The following snippet demonstrates an inefficient way to pass a matrix to a function. The function expects a <var>Matrix</var> but is given a different type resulting from the <var>topLeftCorner</var> method. The argument will thus be implicitly copied into a <var>Matrix</var></p>
        <pre className='c++'><code>{String.raw
`void f(const Eigen::MatrixX<float>& m)
{
    // some math
}

int main()
{
    Eigen::MatrixXf m = Eigen::MatrixXf::Random(4, 4);
    f(m.topLeftCorner(3,3));
}`}
        </code></pre>
        <p>A better alternative is to use the <var>Eigen::Ref</var> class which can either represent a <var>MatrixX</var> or a block of it.</p>
        <pre className='c++'><code>{String.raw
`void f(Eigen::Ref<const Eigen<MatrixX<float>> m)
{
    // some math
}

int main()
{
    Eigen::MatrixXf m = Eigen::MatrixXf::Random(4, 4);
    f(m.topLeftCorner(3,3));
    f(m + Eigen::MatrixXf::Identity(4, 4)) 
}`}
        </code></pre>
        <p>The first of these calls does't make any copy, the latter creates a temporary as the expression memory layout doesn't match the memory layout accepted by the <var>Ref</var> object. This is actually an example comming from the <a href="https://eigen.tuxfamily.org/dox/TopicFunctionTakingEigenTypes.html"  target="_blank" rel="noopener noreferrer">Eigen documentation</a> that I highly suggest reading.</p>
    </div>)
}

export default Article1;